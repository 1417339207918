import SComponents from "@too-doo/components/src";

import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(SComponents);

console.log('hello world')

window.Office.initialize = () => {
  new Vue({
    render: h => h(App),
  }).$mount('#app')
}