<template>
  <div id="app">

    <div id="login" style="padding: 20px 20px" v-if="showLogin">    
      <h1 class="c-header__title" style="text-align: center; min-width: 120px; padding: 0; display: block">Welcome</h1>
      <p style="text-align: center">Organize meetings from your calendar and create the meetings automatically in too-doo</p>
      <hr />
      
      <h1 class="c-header__title" style="text-align: center; min-width: 120px; padding: 0; margin-top: 24px; display: block">Login</h1>
      <p style="text-align: center">Login with your too-doo credentials.</p>
      <s-input 
        id="username"
        name="username"
        label="E-mail"
        type="text"
        placeholder="username"
        v-model="loginUsername"
      />
      <s-input 
        id="password"
        name="password"
        label="password"
        type="password"
        placeholder="password"
        v-model="loginPassword"
      />
      <div class="o-flex">
        <div class="c-card--login__left">
          <div class="c-card--login__button-group u-margin-top-medium" style="text-align: center">
            <s-button class="u-1-of-1 u-margin-bottom-small c-button--blue c-button--rounded" style="background-color: #00b6ff;padding: 14px 16px 12px;color: #fff;" @click="normalLogin">Login</s-button>
            <span style="color: #9b9b9b; text-align: center; ">OR</span>
            <s-button class="u-1-of-1 u-margin-top-small u-text-none c-button--rounded icon-left" style="padding:  14px 16px 12px;color:  #fff;background-color: #e44029;" @click="dialogFallback">
              <s-icon name="ms-office" small></s-icon>Sign in with Office 365
            </s-button>
          </div>
        </div>
      </div>  
    </div>
   
    <div style="padding: 20px 20px"  v-if="companies.length > 0">
       <s-input-select
        id="company"
        name="company"
        label="Select Company *"
        :options="companies" optionLabel="name"
        placeholder="Select Company"
        v-model="selectedCompany"
        @change="companySelectChange"
      />

      <s-input-select
        v-if="selectedCompany &&  branches.length > 0"
        id="branch"
        name="branch"
        label="Select Branch *"
        :options="branches" optionLabel="name"
        placeholder="Select Branch"
        v-model="selectedBranch"
        @change="branchSelectChange"
      />

      <s-input-select
        v-if="selectedBranch"
        id="type"
        name="type"
        label="Select meetingtype *"
        :options="filteredMeetingTypes" optionLabel="type"
        placeholder="Select meetingtype"
        v-model="selectedMeetingType"
        @change="meetingTypeSelectChange"
      />

      <s-input
        v-if="false"
        id="subject"
        name="subject"
        label="Subject *"
        type="text"
        placeholder="Meeting subject"
        v-model="meetingSubject"
      />

      <fieldset class="c-input-group" v-if="invitees.length > 0">
        <legend class="c-input-group__title">Participants  *</legend>

        <div class="c-card c-card--user" v-for="(invitee, index) in invitees" :key="invitee.email">
          <s-input-checkbox
            small
            name="checkbox-present-people"
            :id="`user-${index}`"
            vcentered
            :modelValue="true"
            @input="chekboxChanged"
          >
            <s-avatar
              small
              labelledAvatar
              :user-name="invitee.name"
              user-img="https://app.too-doo.be/image/placeholder.png"
            />
          </s-input-checkbox>
        </div>
      </fieldset>

      <fieldset class="c-input-group" v-if="guests.length > 0">
        <legend class="c-input-group__title">Guests </legend>

        <div class="c-card c-card--user" v-for="(guest, index) in guests" :key="guest.email">
          <s-input-checkbox
            small
            name="checkbox-present-people"
            :id="`guest-${index}`"
            vcentered
            :modelValue="true"
          >
            <s-avatar
              small
              labelledAvatar
              :user-name="guest.name"
              user-img="https://app.too-doo.be/image/placeholder.png"
            />
          </s-input-checkbox>
        </div>
      </fieldset>

      <!-- <fieldset class="c-input-group" v-if="invitees.length > 0 || true">
        <legend class="c-input-group__title">Gasten *</legend> -->

        <!-- <s-input-select
          id="guests"
          name="guests"
          label="Select guests"
          :options="possibleGuests" optionLabel="name" optionValue="email"
          placeholder="Select guests"
          v-model="selectedGuest"
          @change="guestSelectChange"

        /> -->
      <!-- </fieldset> -->
    </div>
    <div><a href="#" style="position: absolute; bottom: 15px; right: 25px; color: gray;" @click="logout">logout from too-doo</a></div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
const sso = require('office-addin-sso');
const axios = require('axios').default;

var loginDialog

const addLeadingZero = (int) => {
  if(int < 10){
    return `0${int}`;
  }
  return `${int}`;
}

export default {
  name: 'App',
  mounted: async function(){
    console.log('APPVUE, mounted')
    //this.checkToken();
    this.toodooTokenLogin();

    setInterval(() => {
      // console.log('check companyselected', this.selectedCompany, this.selectedBranch)
      window.Office.context.mailbox.item.subject.getAsync(result => {
        let save = false
        if(this.meetingId && this.meetingSubject !== result.value){
          this.meetingSubject = result.value;
          this.meetingSubjectFull = result.value;
          console.log('interval subject', result.value);
          save = true;
        }

        window.Office.context.mailbox.item.start.getAsync((asyncResult) => {
          
          const newStart = new Date(asyncResult.value.getTime());
          if(this.meetingDate && newStart.getTime() !== this.meetingDate.getTime()){
            console.log('interval start', result.value);
            this.meetingDate = newStart;
            save = true;
          }

          // eslint-disable-next-line no-constant-condition
          if(save){
            this.saveOutlook();
          }
        })

      })
    },1000)
  },
  data() {
    return {
      showLogin: false,
      loginUsername: '',
      loginPassword: '',
      meetingId: null,
      msToken: '',
      msGraphToken: '',
      tooDooToken: '',
      selectedCompany: '',
      selectedBranch: null,
      selectedMeetingType: null,
      selectedGuest: null,
      invitees: [],
      guests: [],
      possibleGuests: [],
      companies: [],
      branches: [],
      meetingtypes: [
        // {
        //   type: "IT meeting",
        //   invitees: [
        //     {
        //       email: "gert@too-doo.be",
        //       name: "Gert Van De Vijver"
        //     },
        //     {
        //       email: "lisa@skillls.com",
        //       name: "Lisa Van Daele"
        //     },{
        //       email: "bart@constantbeta.be",
        //       name: "Bart Chanet"
        //     }],
        //   value: "it_meeting"
        // },
        // {
        //   type: "Management meeting",
        //   invitees: [
        //     {
        //       email: "gert@too-doo.be",
        //       name: "Gert Van De Vijver"
        //     },
        //     {
        //       email: "bbrt@constantbeta.be",
        //       name: "Bart Chanet"
        //     }
        //   ],
        //   value: "management_meeting"
        // }
      ],
      filteredMeetingTypes: [],
      allPossibleGuests: [
            // {
            //   email: "karen@too-doo.be",
            //   name: "Karen Ost"
            // },
            // {
            //   email: "lisa@skillls.com",
            //   name: "Lisa Van Daele"
            // }
      ],
      meetingSubject: "",
      meetingSubjectFull: '',
      meetingDate: '',
      inviteeCheckboxes: []
    }
  },
  methods: {
    companySelectChange: function(value) {
      console.log('companySelectChange', value, this.selectedCompany);
      this.selectedCompany = value;
    },
    branchSelectChange: function(value) {
      console.log('branchSelectChange', value, this.selectedBranch);
      this.selectedBranch = value;
    },
    meetingTypeSelectChange: function(value) {
      console.log('meetingTypeSelectChange', value, this.selectedMeetingType);
      this.selectedMeetingType = value;
    },
    guestSelectChange: function(value) {
      console.log('guestSelectChange', value, this.selectedGuest);
      this.selectedGuest = value;
    },
    logout: function(){
      console.log('do logout');
      localStorage.removeItem('toodoosso')
      window.location.reload();
    },
    setOutlook: function(){
      let prefix = this.meetingtypes.find(mt => mt.value == this.selectedMeetingType).type;
      if(this.meetingSubject !== ''){
        prefix += ':'
      }

      this.meetingSubjectFull = `${prefix} ${this.meetingSubject}`;

      window.Office.context.mailbox.item.subject.setAsync(this.meetingSubjectFull);

      const outlookInvitees = this.invitees.map(i => {
        return {
          displayName: i.name,
          emailAddress: i.email
        }
      })
      const outlookGuests = this.guests.map(i => {
        return {
          displayName: i.name,
          emailAddress: i.email
        }
      })

      window.Office.context.mailbox.item.requiredAttendees.setAsync([...outlookInvitees, ...outlookGuests]);

      window.Office.context.mailbox.item.start.getAsync((asyncResult) => {
        const startTime = asyncResult.value;
        this.meetingDate = new Date(asyncResult.value.getTime());

        const endTime = new Date(asyncResult.value.getTime() + 1000*60*60*1)
        console.log('starttime', typeof startTime, startTime, endTime);

        window.Office.context.mailbox.item.end.setAsync(endTime)
      })


    },
    saveOutlook: function(){
      console.log('save outlook');
      window.Office.context.mailbox.item.saveAsync(async(result) => {
        console.log('message saved', result.status, result.value, result.error);

        // save to too-doo
        console.log('token', this.tooDooToken);

        const meetingDateString = `${this.meetingDate.getFullYear()}-${addLeadingZero(this.meetingDate.getMonth() + 1)}-${addLeadingZero(this.meetingDate.getDate())}`;

        console.log('fd branch', meetingDateString);

        var FormData = require('form-data');
        var data = new FormData();
        data.append('branch_id', this.selectedBranch);
        data.append('meeting_type', this.selectedMeetingType);
        data.append('title', this.meetingSubjectFull);
        data.append('event_id', result.value)
        this.invitees.forEach((i, index) => {
          data.append(`attendees[${index}]`, i.id)
        })

        // data.append('attendees[0]', '10389');
        // data.append('attendees[1]', '10394');
        data.append('meeting_date', meetingDateString);

        if(!this.meetingId){
          console.log('is a new meeting')
          const meeting = await axios.post('https://app.too-doo.be/api/meeting/create-meeting', data, {
            headers: {
              Authorization: `Bearer ${this.tooDooToken}`
            }
          })

          console.log('meeting saved', meeting.data);
          this.meetingId = meeting.data.meeting_id;
        }else{
          console.log('is a EXISTING meeting');

          data.append('id', this.meetingId)

          const meeting = await axios.post('https://app.too-doo.be/api/meeting/update-meeting', data, {
            headers: {
              Authorization: `Bearer ${this.tooDooToken}`
            }
          })

           console.log('meeting updated', meeting.data);
        }
      })
    },
    chekboxChanged: function(e){
      console.log('chekboxChanged', e, this.inviteeCheckboxes)
    },
    checkToken: async function(){
      try{
        console.log('start token')





        const bootstrapToken = await window.OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: false });
        console.log('bootstraptoken', bootstrapToken)

        this.msToken = bootstrapToken;
          
        sso.getGraphToken(bootstrapToken).then(async(response2) => {
          console.log('response2 ', response2)

          if(response2.error || response2.message === 'invalid_user'){
            console.log('login needed')
            this.showLogin = true;
            // const existingSSOToken = localStorage.getItem('toodoosso');
            // console.log('has existing token', existingSSOToken);
            // if(existingSSOToken){
            //   const fullUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login';

            //   try{
            //     const loginResponse = await axios.post(fullUrl, {
            //       sso: existingSSOToken
            //     });
            //     console.log('sso response', loginResponse);
            //     this.getCompanies(loginResponse.data);
            //   }catch(error){
            //     console.log('sso error', error);
            //     this.showLogin = true;
            //   }
            // }else{
            //   this.showLogin = true;
            // }
            // this.dialogFallback();
          }else{
            console.log('this graph', response2.companies)

            this.getCompanies(response2);
          }
        })
      }catch(error){
        console.log('getAccessToken error', error);
        this.showLogin = true;
      }
      console.log('lalalaa xa')
    },
    toodooTokenLogin: async function() {
      const existingSSOToken = localStorage.getItem('toodoosso');
      console.log('has existing token', existingSSOToken);
      if(existingSSOToken){
        const fullUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login';

        try{
          const loginResponse = await axios.post(fullUrl, {
            sso: existingSSOToken
          });
          console.log('sso response', loginResponse);
          this.getCompanies(loginResponse.data);
        }catch(error){
          console.log('sso error', error);
          this.showLogin = true;
        }
      }else{
        // this.showLogin = true;
        this.checkToken();
      }
    },
    normalLogin: async function(){
      const fullUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login';
      console.log('normalLogin', fullUrl);

      const loginResponse = await axios.post(fullUrl, {
        username: this.loginUsername, 
        password: this.loginPassword
      });
      
      console.log('loginResponse', loginResponse);
      localStorage.setItem('toodoosso', loginResponse.data.sso)
      this.getCompanies(loginResponse.data);
    },
    getCompanies: function(loginResponse){
      console.log('loginResponse', loginResponse);
      this.showLogin = false;
      this.companies = loginResponse.companies.map(r => {
        return {
        name: r.name,
        value: r.token
      }})
    },
    dialogFallback: function(){
      var fullUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/dialog.html';
      console.log('furllurl', fullUrl);

      const self = this;
      window.Office.context.ui.displayDialogAsync(fullUrl,
        { height: 60, width: 30 }, function (result) {
          console.log("Dialog has initialized. Wiring up events", result);
          loginDialog = result.value;
          loginDialog.addEventHandler(window.Microsoft.Office.WebExtension.EventType.DialogMessageReceived, self.processMessage);
        });
    },
    processMessage: function(args){
      console.log("Message received in  : ", args);
      const message = args && args.message && JSON.parse(args.message);
      console.log('message', message);
      if(message && message.status && message.status === 'failure'){
        console.log('failed')
        
      }
      this.checkToken();


      
      loginDialog.close();
    }
  },
  watch: {
    selectedCompany: async function(newVal){
      console.log('selectedCompany', newVal)
      this.tooDooToken = newVal;
      const meetingtypesRequest = await axios.get('https://app.too-doo.be/api/meeting/meeting-types', {
        headers: {
          Authorization: `Bearer ${newVal}`
        }
      });
      this.branches = [];
      this.meetingtypes = meetingtypesRequest.data.map(d => {
        console.log('d', d)
        if(!this.branches.find(b => d.branch.id === b.id)){
          d.branch.value = d.branch.id;
          this.branches.push(d.branch)
        }
        return {
          type: d.name,
          value: d.id,
          branch: d.branch.id,
          invitees: d.members
        }
      });
      // console.log('this.meetingtypes', meetingtypes);
    },
    selectedBranch: function(newVal){
      console.log('selectedBranch', typeof newVal, newVal)
      this.filteredMeetingTypes = this.meetingtypes.filter(mt => mt.branch == newVal);
    },
    selectedMeetingType: function(newVal, oldVal){
      console.log('watch', newVal, oldVal);
      this.invitees = this.meetingtypes.find(mt => mt.value == newVal).invitees;
      this.invitees.forEach(i => {
        i.active = true;
      })
      this.guests = [];

      console.log('test')
      this.setOutlook();

      this.saveOutlook();
    },
    meetingSubject: function(){
      // this.setOutlook();
    },
    invitees: function(){
      console.log('invitees', this.invitees)
      // this.possibleGuests = this.allPossibleGuests.filter(apg => this.invitees.indexOf(apg.email) === -1 && this.guests.indexOf(apg.email) === -1).map((g, index) => {
      //   return {id: index,
      //     name: g.name,
      //     email: g.email
      //   }
      // });

      this.possibleGuests = this.allPossibleGuests.filter(apg => !this.invitees.find(i => i.email === apg.email) && !this.guests.find(i => i.email === apg.email) )
      this.setOutlook();
    },
    guests: function(){
      console.log('gue', this.possibleGuests)

      this.possibleGuests = this.allPossibleGuests.filter(apg => !this.invitees.find(i => i.email === apg.email) && !this.guests.find(i => i.email === apg.email) )
      // this.possibleGuests = this.allPossibleGuests.filter(apg => this.invitees.indexOf(apg.email) === -1 && this.guests.indexOf(apg.email) === -1).map((g, index) => {
      //   return {id: index,
      //     name: g.name,
      //     email: g.email
      //   }
      // });
      this.setOutlook();
    },
    selectedGuest: function(newVal){
      console.log('selected', newVal)
      if(newVal && newVal !== ''){
        const guest = this.allPossibleGuests.find(apg => apg.email === newVal)
        guest.active = true;
        this.guests.push(guest)
      }

      this.selectedGuest = null;
    }
  }
}
</script>

<style>
@import '~@too-doo/components/src/scss/all.css';
@import url('https://fonts.googleapis.com/css?family=Nunito|Comfortaa');

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}
</style>
